// ChatBot.js
import ReactPlayer from "react-player";
import knownQA from "./knownQA.js";
import React, { useState, useRef, useEffect, useMemo } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { NODE_ENV } from "./constants/config";
import {
  FaUser,
  FaTimes,
  FaCommentDots,
  FaRegCopy,
  FaArrowDown,
} from "react-icons/fa";
import ReactMarkdown from "react-markdown";
import { v4 as uuidv4 } from "uuid"; // For generating unique session IDs
import { useNavigate } from "react-router-dom";

// Add the new knownQA question if it's not already present
const demoQuestion = "Give me a demo of Guided Automation";
if (!knownQA.some((qa) => qa.question === demoQuestion)) {
  knownQA.push({
    question: demoQuestion,
    answer:
      "Sure! I'd be happy to guide you through a demo of our Guided Automation feature.",
  });
}

// Set the default logo URL
const defaultBotLogo = "https://www.kimavi.com/logo.png"; // Default logo

// Fisher-Yates Shuffle Algorithm
const shuffleArray = (array) => {
  const shuffled = [...array];
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
};

function ChatBot({
  modal = false,
  isOpen: propIsOpen = false,
  onClose,
  iframeMode = false,
  botId: propBotId,
  customization: propCustomization = {},
}) {
  const navigate = useNavigate(); // For navigation
  const [askedQuestions, setAskedQuestions] = useState([]);

  // Retrieve botId and customization from URL parameters if not provided via props
  const urlParams = new URLSearchParams(window.location.search);
  const botId = propBotId || urlParams.get("botId") || "kimavi-101"; // Default botId

  // Generate a unique session ID
  const [sessionId] = useState(uuidv4());

  // Default colors
  const defaultHeaderBackgroundColor = "#000000";
  const defaultHeaderFontColor = "#00ffdd";
  const defaultUserIconColor = "#2DD4BF"; // teal color
  const defaultIconBackgroundColor = "#000000"; // black
  const defaultIconFrontColor = "#00ffdd"; // teal color
  const defaultUserBackgroundColor = "#000000"; // black
  const defaultUserFontColor = "#00ffdd"; // teal color
  const defaultBackgroundColor = "#ffffff"; // white
  const defaultForegroundColor = "#000000"; // black
  const defaultButtonBackgroundColor = "#000000"; // black
  const defaultButtonFontColor = "#00ffdd"; // teal color
  const defaultBotMessageBackgroundColor = "#e2e8f0"; // gray-300
  const defaultBotMessageTextColor = "#2d3748"; // gray-800

  const [thinkingDots, setThinkingDots] = useState("");

  // Initialize customization settings
  const [customization, setCustomization] = useState({
    logo: urlParams.get("logo") || propCustomization.logo || defaultBotLogo,
    backgroundColor:
      urlParams.get("backgroundColor") ||
      propCustomization.backgroundColor ||
      defaultBackgroundColor,
    foregroundColor:
      urlParams.get("foregroundColor") ||
      propCustomization.foregroundColor ||
      defaultForegroundColor,
    fontFamily:
      urlParams.get("fontFamily") ||
      propCustomization.fontFamily ||
      "Arial, sans-serif",
    headerTitle:
      urlParams.get("headerTitle") ||
      propCustomization.headerTitle ||
      "Kimavi Chatbot - Built on ChatGPT",
  });

  // Initialize isOpen state
  const [isOpen, setIsOpen] = useState(iframeMode ? true : propIsOpen);

  // Initialize messages with default greeting
  const [messages, setMessages] = useState([
    {
      sender: "bot",
      text: `👋 **Hello!** I'm here to help you out! 😊  
          
I'm an AI chatbot trained on Kimavi products:  **Chat**, **Guides**, and **Videos**.   
    
    
   
    
Just ask me _anything_ about Kimavi products! 😊
`,
      isInitial: true, // Mark this message as the initial one
    },
  ]);

  // Initialize suggestions with default questions
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    // Shuffle questions and pick the first two (excluding the always-on demo question)
    const filteredQA = knownQA.filter((qa) => qa.question !== demoQuestion);
    const shuffledQuestions = shuffleArray(filteredQA);
    const initialSuggestions = shuffledQuestions
      .slice(0, 2)
      .map((qa) => qa.question);
    // Always include the demo question
    setSuggestions([...initialSuggestions, demoQuestion]);
  }, []);

  const [input, setInput] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [botResponse, setBotResponse] = useState(""); // For typing simulation
  const [typingIntervalId, setTypingIntervalId] = useState(null);

  const [showPopup, setShowPopup] = useState(true); // State to control the pop-up visibility

  const CHAR_LIMIT = 120;

  const userMessagesCount = messages.filter(
    (msg) => msg.sender === "user"
  ).length;

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (value.length <= CHAR_LIMIT) {
      setInput(value);
    }
  };

  useEffect(() => {
    let dotsInterval;
    if (isTyping && !botResponse) {
      dotsInterval = setInterval(() => {
        setThinkingDots((prevDots) => {
          if (prevDots.length >= 5) {
            return "";
          } else {
            return prevDots + ".";
          }
        });
      }, 500); // Adjust the interval as needed
    } else {
      setThinkingDots("");
    }

    return () => {
      if (dotsInterval) clearInterval(dotsInterval);
    };
  }, [isTyping, botResponse]);

  const messagesEndRef = useRef(null);
  const lastUserMessageRef = useRef(null);
  const lastBotMessageRef = useRef(null);

  const apiBaseUrl =
    NODE_ENV === "development"
      ? "http://localhost:5001/json-storage-bed47/us-central1/api"
      : "https://us-central1-json-storage-bed47.cloudfunctions.net/api";

  // Fetch initial data (e.g., configurations from the server)
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/chatbot-config`, {
          params: { botId },
        });
        const config = response.data;

        // Merge initial messages
        if (config.initialMessages) {
          setMessages(config.initialMessages);
        }

        // Merge suggestions
        if (config.suggestions) {
          setSuggestions((prev) => [...prev, ...config.suggestions]);
        }

        // Merge customization settings
        setCustomization((prevCustomization) => ({
          ...prevCustomization,
          ...config.customization,
        }));
      } catch (error) {
        console.error("Error fetching chatbot configuration:", error);
        // Use default messages and suggestions already set in state
      }
    };

    // fetchInitialData();
  }, [botId]);

  // Keep track of previous message count to scroll as needed
  const previousMessageCount = useRef(messages.length);

  useEffect(() => {
    // When the number of messages increases
    if (messages.length > previousMessageCount.current) {
      const lastMessage = messages[messages.length - 1];

      // Scroll to the last user message when the user sends a message
      if (lastMessage.sender === "user") {
        lastUserMessageRef.current?.scrollIntoView({ behavior: "smooth" });
      }

      // Scroll to the last bot message when the bot responds
      if (lastMessage.sender === "bot") {
        lastBotMessageRef.current?.scrollIntoView({ behavior: "smooth" });
      }

      previousMessageCount.current = messages.length;
    }
  }, [messages]);

  // Clean up typing interval on unmount
  useEffect(() => {
    return () => {
      if (typingIntervalId) {
        clearInterval(typingIntervalId);
      }
    };
  }, [typingIntervalId]);

  // Auto-close the pop-up after 10 seconds
  useEffect(() => {
    let popupTimer;
    if (showPopup) {
      popupTimer = setTimeout(() => {
        setShowPopup(false);
      }, 10000); // 10000 milliseconds = 10 seconds
    }

    return () => {
      if (popupTimer) clearTimeout(popupTimer);
    };
  }, [showPopup]);

  const [expectingEmail, setExpectingEmail] = useState(false);
  const [emailCollected, setEmailCollected] = useState(false);

  const sendMessage = async (messageText = input) => {
    if (messageText.trim() === "") return;

    // Email validation function
    const validateEmail = (email) => {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    };

    // Check if we're expecting an email
    if (expectingEmail) {
      if (validateEmail(messageText)) {
        // Email is valid
        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: "user", text: messageText },
        ]);
        setEmailCollected(true);

        // Send email to backend
        await getBotResponse(messageText);

        // Bot thanks the user
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            sender: "bot",
            text: "Thank you for providing your email! We will be in touch via email. For further help you can also email us at admin@kimavi.com",
          },
        ]);

        setExpectingEmail(false);
        setInput("");
        return;
      } else {
        // Email is invalid
        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: "user", text: messageText },
          {
            sender: "bot",
            text: "Oops! That doesn't look like a valid email address. Could you please double-check and provide a valid email?",
          },
        ]);
        setInput("");
        return;
      }
    }

    // Prevent further interactions after 10 user messages if email not collected
    const userMessagesCount = messages.filter(
      (msg) => msg.sender === "user"
    ).length;
    if (userMessagesCount >= 10 && !emailCollected) {
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "user", text: messageText },
        {
          sender: "bot",
          text: "For further assistance, please contact us at admin@kimavi.com. Our team will reach out to help you further once we have your email address.",
        },
      ]);
      setInput("");
      return;
    }

    const userMessage = { sender: "user", text: messageText };
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setInput("");
    setIsTyping(true);

    try {
      const { text: responseText, question: answeredQuestion } =
        await getBotResponse(messageText);

      // Update asked questions
      if (answeredQuestion) {
        setAskedQuestions((prevAskedQuestions) => [
          ...prevAskedQuestions,
          answeredQuestion,
        ]);
      }

      // Show bot response
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "bot", text: responseText },
      ]);
      setIsTyping(false);

      // Check for email request
      if (checkForEmailRequest(responseText)) {
        setExpectingEmail(true);
      }

      // Show email request after 5 interactions
      if (userMessagesCount >= 4 && !emailCollected && !expectingEmail) {
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            sender: "bot",
            text: "To help us serve you better, please provide your email address.",
          },
        ]);
        setExpectingEmail(true);
      }
    } catch (error) {
      console.error("Error sending message:", error);
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "bot", text: "Sorry, something went wrong." },
      ]);
      setIsTyping(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent form submission if inside a form
      sendMessage();
    }
  };

  const handleSuggestionClick = (suggestion) => {
    // If user clicks the "Give me a demo of Guided Automation" suggestion,
    // redirect instead of sending a message.
    if (suggestion === demoQuestion) {
      window.open("/ai?demo=automated", "_blank");
    } else {
      sendMessage(suggestion);
    }
  };

  const remainingSuggestions = useMemo(() => {
    return knownQA
      .filter(
        (qa) =>
          !askedQuestions.includes(qa.question) && qa.question !== demoQuestion
      )
      .map((qa) => qa.question);
  }, [askedQuestions]);

  const getBotResponse = async (userInput) => {
    const matchingQA = knownQA.find(
      (qa) => qa.question.toLowerCase() === userInput.toLowerCase()
    );

    let customerId = "101"; // Default customer ID

    if (matchingQA) {
      setIsTyping(true); // Start thinking indicator

      // Simulate thinking for 3 seconds
      await new Promise((resolve) => setTimeout(resolve, 3000));

      setIsTyping(false); // Stop thinking indicator
      return { text: matchingQA.answer, question: matchingQA.question };
    } else {
      // No local match, call backend API
      try {
        const payload = {
          botId,
          customerId,
          sessionId,
          message: userInput,
          messages,
        };

        const response = await axios.post(`${apiBaseUrl}/chat`, payload, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        return { text: response.data.reply };
      } catch (error) {
        console.error("Error fetching bot response:", error);
        toast.error("Failed to get response from the chatbot.");
        return { text: "Sorry, I am having trouble responding right now." };
      }
    }
  };

  const checkForEmailRequest = (botMessage) => {
    const emailRequestPhrases = [
      "could you please share your email address",
      "please provide your email address",
      "could you provide your email address",
      "can you share your email address",
      "may I have your email address",
      "what's your email address",
      "please provide your email",
      "to help us serve you better",
    ];
    const lowerCaseMessage = botMessage.toLowerCase();
    return emailRequestPhrases.some((phrase) =>
      lowerCaseMessage.includes(phrase)
    );
  };

  const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("Successfully copied.", {
          position: "bottom-center",
        });
      })
      .catch((err) => {
        console.error("Could not copy text: ", err);
        toast.error("Failed to copy text.");
      });
  };

  const { logo, backgroundColor, foregroundColor, fontFamily, headerTitle } =
    customization;

  const renderMessageContent = (msg, idx) => {
    if (msg.sender === "bot") {
      const isYouTubeLink =
        /^https?:\/\/(www\.)?youtube\.com\/(watch\?v=|shorts\/)/.test(msg.text);

      const shouldDisplaySuggestions =
        !emailCollected &&
        !expectingEmail &&
        !(
          msg.text.includes("please provide your email") ||
          msg.text.includes("could you please share your email")
        );

      let displayedSuggestions = [];
      if (shouldDisplaySuggestions) {
        if (idx === 0) {
          displayedSuggestions = suggestions;
        } else {
          // Up to two remaining suggestions + demo question always
          const filteredSuggestions = remainingSuggestions.slice(0, 2);
          displayedSuggestions = [...filteredSuggestions, demoQuestion];
        }
      }

      if (isYouTubeLink) {
        return (
          <div>
            <p>Please watch this video!</p>
            <ReactPlayer url={msg.text} controls width="100%" height="auto" />
            {displayedSuggestions.length > 0 && (
              <div className="mt-5 mb-2 flex flex-wrap gap-2">
                {displayedSuggestions.map((suggestion, i) => (
                  <button
                    key={i}
                    className={
                      suggestion === "Give me a demo of Guided Automation"
                        ? "text-left px-2 py-1 rounded-md text-white bg-gradient-to-r from-red-500 via-orange-500 via-yellow-500 via-green-500 via-blue-500 via-indigo-500 to-purple-500 animate-pulse hover:shadow-lg"
                        : "text-left px-2 py-1 bg-gray-400 text-gray-100 rounded-md hover:bg-gray-600"
                    }
                    onClick={() => handleSuggestionClick(suggestion)}
                  >
                    {suggestion}
                  </button>
                ))}
              </div>
            )}
          </div>
        );
      } else {
        return (
          <div>
            <ReactMarkdown
              components={{
                p: ({ node, children, ...props }) => (
                  <p className="mb-2" {...props}>
                    {children}
                  </p>
                ),
                h1: ({ node, children, ...props }) => (
                  <h1 className="text-2xl font-bold my-2" {...props}>
                    {children}
                  </h1>
                ),
                h2: ({ node, children, ...props }) => (
                  <h2 className="text-xl font-semibold my-2" {...props}>
                    {children}
                  </h2>
                ),
                h3: ({ node, children, ...props }) => (
                  <h3 className="text-lg font-semibold my-2" {...props}>
                    {children}
                  </h3>
                ),
                h4: ({ node, children, ...props }) => (
                  <h4 className="text-base font-semibold my-2" {...props}>
                    {children}
                  </h4>
                ),
                ul: ({ node, children, ...props }) => (
                  <ul className="list-disc ml-5" {...props}>
                    {children}
                  </ul>
                ),
                ol: ({ node, children, ...props }) => (
                  <ol className="list-decimal ml-5" {...props}>
                    {children}
                  </ol>
                ),
                li: ({ node, children, ...props }) => (
                  <li className="my-1" {...props}>
                    {children}
                  </li>
                ),
                code: ({ node, inline, className, children, ...props }) => (
                  <code className="bg-gray-100 p-1 rounded" {...props}>
                    {children}
                  </code>
                ),
                strong: ({ node, children, ...props }) => (
                  <strong {...props}>{children}</strong>
                ),
                em: ({ node, children, ...props }) => (
                  <em {...props}>{children}</em>
                ),
                blockquote: ({ node, children, ...props }) => (
                  <blockquote
                    className="border-l-4 border-gray-300 pl-4 italic my-2"
                    {...props}
                  >
                    {children}
                  </blockquote>
                ),
                hr: ({ node, ...props }) => <hr className="my-4" {...props} />,
                img: ({ node, ...props }) => (
                  <img className="my-2 max-w-full" {...props} alt="" />
                ),
                a: ({ node, children, ...props }) => (
                  <a
                    className="text-blue-500 hover:underline"
                    target="_blank"
                    rel="noopener noreferrer"
                    {...props}
                  >
                    {children}
                  </a>
                ),
              }}
            >
              {msg.text}
            </ReactMarkdown>
            {displayedSuggestions.length > 0 && (
              <div className="mt-5 mb-2 flex flex-wrap gap-2 ">
                {displayedSuggestions.map((suggestion, i) => (
                  <button
                    key={i}
                    className={
                      suggestion === "Give me a demo of Guided Automation"
                        ? "text-left px-2 py-1 rounded-md text-white bg-gradient-to-r from-red-500 via-orange-500 via-yellow-500 via-green-500 via-blue-500 via-indigo-500 to-purple-500 animate-pulse hover:shadow-lg"
                        : "text-left px-2 py-1 bg-gray-400 text-gray-100 rounded-md hover:bg-gray-600"
                    }
                    onClick={() => handleSuggestionClick(suggestion)}
                  >
                    {suggestion}
                  </button>
                ))}
              </div>
            )}
          </div>
        );
      }
    } else {
      return <span>{msg.text}</span>;
    }
  };

  const chatContent = (
    <>
      {/* Messages */}
      <div
        className="flex-1 overflow-y-auto p-4"
        style={{ fontFamily, backgroundColor, color: foregroundColor }}
      >
        {messages.map((msg, idx) => (
          <div
            key={idx}
            className={`flex my-2 ${
              msg.sender === "user" ? "justify-end" : "justify-start"
            }`}
            ref={
              idx === messages.length - 1
                ? msg.sender === "user"
                  ? lastUserMessageRef
                  : lastBotMessageRef
                : null
            }
          >
            {msg.sender === "bot" ? (
              <div className="flex items-start space-x-2">
                <img
                  src={logo}
                  alt="Bot Logo"
                  className="w-10 h-10 rounded-full"
                />

                <div>
                  <div
                    className="p-2 rounded-lg max-w-xs"
                    style={{
                      backgroundColor:
                        customization.botMessageBackgroundColor ||
                        defaultBotMessageBackgroundColor,
                      color:
                        customization.botMessageTextColor ||
                        defaultBotMessageTextColor,
                    }}
                  >
                    {renderMessageContent(msg, idx)}
                  </div>
                  {/* Copy Button */}
                  {idx > 0 &&
                    messages[idx - 1].sender === "user" &&
                    !msg.isInitial && (
                      <div className="flex space-x-2 mt-1">
                        <button onClick={() => handleCopy(msg.text)}>
                          <FaRegCopy className="text-gray-600 hover:text-blue-500" />
                        </button>
                      </div>
                    )}
                </div>
              </div>
            ) : (
              <div className="flex items-end space-x-2">
                <div
                  className="p-2 rounded-lg max-w-xs"
                  style={{
                    backgroundColor:
                      customization.userMessageBackgroundColor ||
                      defaultUserBackgroundColor,
                    color:
                      customization.userMessageTextColor ||
                      defaultUserFontColor,
                  }}
                >
                  {renderMessageContent(msg, idx)}
                </div>
                <FaUser
                  className="text-2xl"
                  style={{
                    color: customization.userIconColor || defaultUserIconColor,
                  }}
                />
              </div>
            )}
          </div>
        ))}

        {/* Bot Typing Simulation */}
        {isTyping && botResponse && (
          <div className="flex items-start space-x-2 my-2">
            <img src={logo} alt="Bot Logo" className="w-10 h-10 rounded-full" />
            <div
              className="p-2 rounded-lg max-w-xs"
              style={{
                backgroundColor:
                  customization.botMessageBackgroundColor ||
                  defaultBotMessageBackgroundColor,
                color:
                  customization.botMessageTextColor ||
                  defaultBotMessageTextColor,
              }}
            >
              <ReactMarkdown>{botResponse}</ReactMarkdown>
            </div>
          </div>
        )}

        {/* Thinking Indicator */}
        {isTyping && !botResponse && (
          <div className="flex items-start space-x-2 my-2">
            <img src={logo} alt="Bot Logo" className="w-10 h-10 rounded-full" />
            <div
              className="p-2 rounded-lg max-w-xs"
              style={{
                backgroundColor:
                  customization.botMessageBackgroundColor ||
                  defaultBotMessageBackgroundColor,
                color:
                  customization.botMessageTextColor ||
                  defaultBotMessageTextColor,
              }}
            >
              Thinking{thinkingDots}
            </div>
          </div>
        )}

        {/* Scroll to bottom */}
        <div ref={messagesEndRef} />
      </div>

      {/* Input Field */}
      {!(emailCollected || userMessagesCount >= 10) && (
        <>
          <div className="p-4 flex items-center space-x-2">
            <input
              className="flex-1 p-2 border border-gray-300 rounded-md focus:outline-none"
              type="text"
              placeholder={
                expectingEmail
                  ? "Enter your email address..."
                  : "Type your message..."
              }
              value={input}
              onChange={handleInputChange}
              onKeyDown={handleKeyPress}
              style={{ fontFamily }}
            />
            <button
              className="px-4 py-2 rounded-md"
              style={{
                backgroundColor: defaultButtonBackgroundColor,
                color: defaultButtonFontColor,
                fontFamily,
              }}
              onClick={() => sendMessage()}
              disabled={input.length === 0 || isTyping}
            >
              Send
            </button>
          </div>

          {/* Character Limit Indicator */}
          {input && input.length > 0 && (
            <div className="px-4 py-2 text-sm text-gray-500 text-center">
              {input.length}/{CHAR_LIMIT}
            </div>
          )}
        </>
      )}

      {/* Privacy Policy */}
      <div className="text-xs text-center p-2">
        By chatting, you agree to our{" "}
        <a
          href="https://www.kimavi.com/privacy"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 hover:underline"
        >
          privacy policy
        </a>
        .
      </div>
    </>
  );

  if (iframeMode) {
    return (
      <div
        className="w-full h-full flex flex-col relative"
        style={{ backgroundColor, color: foregroundColor, fontFamily }}
      >
        {/* Header */}
        <div
          className="p-2 flex justify-between items-center rounded-xl"
          style={{
            backgroundColor: defaultHeaderBackgroundColor,
            color: defaultHeaderFontColor,
          }}
        >
          <h1 className="text-lg font-bold">{headerTitle}</h1>
        </div>
        {chatContent}
      </div>
    );
  } else if (modal) {
    // Modal mode
    if (!isOpen) {
      return null;
    }

    return (
      // Modal Overlay
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        {/* Modal Content */}
        <div
          className="bg-white rounded-lg overflow-hidden w-full max-w-md max-h-full flex flex-col relative"
          style={{ fontFamily }}
        >
          {/* Header */}
          <div
            className="p-2 flex justify-between items-center"
            style={{
              backgroundColor: defaultHeaderBackgroundColor,
              color: defaultHeaderFontColor,
            }}
          >
            <h1 className="text-lg font-bold">{headerTitle}</h1>
            <button onClick={onClose}>
              <FaTimes className="text-xl" />
            </button>
          </div>

          {chatContent}
        </div>
      </div>
    );
  } else {
    // Regular rendering without modal
    return (
      <>
        {/* Pop-up and Chat Bubble Icon */}
        {!isOpen && (
          <>
            {showPopup && (
              <div
                className="fixed bottom-20 right-4 z-50"
                style={{ pointerEvents: showPopup ? "auto" : "none" }}
              >
                {/* Rainbow shell and rounded edges for popup */}
                <div
                  className="shadow-lg p-1 mb-2 relative rounded-lg bg-gradient-to-r from-red-500 via-orange-500 via-yellow-500 via-green-500 via-blue-500 via-indigo-500 to-purple-500"
                  style={{ maxWidth: "600px" }}
                >
                  <div className="bg-white rounded-md p-4">
                    {/* Close Button */}
                    <button
                      className="absolute top-2 right-2 text-gray-700 hover:text-gray-900"
                      onClick={() => setShowPopup(false)}
                    >
                      <FaTimes />
                    </button>
                    <div
                      className="flex items-start cursor-pointer"
                      onClick={() => {
                        setIsOpen(true);
                        setShowPopup(false);
                      }}
                    >
                      <img
                        src={logo}
                        alt="Bot Logo"
                        className="w-8 h-8 rounded-full mr-2"
                      />
                      <div>
                        <p className="text-sm mb-1 animate-pulse">
                          👋 Hello! Thank you for visiting us. I'm Kimavi, an AI
                          Agent 👋
                        </p>
                        <hr />
                        <p className="text-sm">
                          💡 Do Try me out! I can help you with your queries 💡
                          <br />
                          <br />
                          🚀 I can even give a demo! 🚀
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="fixed bottom-4 right-4 z-100">
              <button
                className="p-3 rounded-full"
                onClick={() => {
                  setIsOpen(true);
                  setShowPopup(false);
                }}
                style={{
                  backgroundColor: defaultIconBackgroundColor,
                  color: defaultIconFrontColor,
                }}
              >
                <FaCommentDots className="text-2xl" />
              </button>
            </div>
          </>
        )}

        {/* Chat Window with fixed height and internal scrolling */}
        {isOpen && (
          <div
            className="fixed bottom-0 right-0 w-full max-w-md z-[1000] p-1
               bg-gradient-to-r from-red-500 via-orange-500 via-yellow-500
               via-green-500 via-blue-500 via-indigo-500 to-purple-500 
               rounded-lg h-[600px]" // Fixed height for the chat window
          >
            <div
              className="h-full shadow-lg flex flex-col rounded-lg"
              style={{
                backgroundColor,
                color: foregroundColor,
                fontFamily,
              }}
            >
              {/* Header */}
              <div
                className="p-2 flex justify-between items-center"
                style={{
                  backgroundColor: defaultHeaderBackgroundColor,
                  color: defaultHeaderFontColor,
                }}
              >
                <h1 className="text-lg font-bold">{headerTitle}</h1>
                <button onClick={() => setIsOpen(false)}>
                  <FaTimes className="text-xl" />
                </button>
              </div>

              {/* Messages and Input Field - with internal scrolling */}
              <div className="flex-1 overflow-y-auto">{chatContent}</div>

              {/* Close Icon at Bottom Right */}
              <div className="p-2 flex justify-end">
                <button
                  className="p-2 rounded-full"
                  onClick={() => setIsOpen(false)}
                  style={{
                    backgroundColor: defaultIconBackgroundColor,
                    color: defaultIconFrontColor,
                  }}
                >
                  <FaArrowDown className="text-xl" />
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default ChatBot;
